import { navigate } from "gatsby";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { shortLangCode } from "../../../common/helpers/helpers";
import placeholderImage from "../../../images/placeholders/placeholder.png";
import ImageLoader from "../../modules/ImageLoader/imageLoader";
import { Store } from "../../Store/store";
import PageLoader from "../PageLoader/pageLoader";

const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;    
    flex-direction: column;    

    @media only screen and (min-width: 1024px){
        
    }    
`;

const TabNav = styled.div`
    display: flex;        
    margin-bottom: 30px;
    overflow-x: auto;
    margin-left:-30px;
    margin-right: -30px;   
    //scroll-padding-left: 30px;
    //scroll-padding-right: 30px;
    //scroll-margin-right: 30px;
    scroll-behavior: smooth;
    
`;

const TabBtn = styled.button<TabBtnProps>`
    //box-sizing: border-box;
    position: relative;
    padding: 0;    
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: 600;       
    color: ${props => props.isActive ? "var(--colorSecondary)" : "var(--colorPrimary)"};
    opacity: ${props => props.isActive ? 1 : 0.49};
    transition: opacity linear 0.2s;
    cursor: pointer;
    
    margin-right: 30px;       
    

    &:hover{
        text-decoration: underline;
    }  

    &:first-of-type{
        margin-left: 30px;
    }  

    &:last-of-type{
        margin-right: 30px;
        /* margin hack */
        &::after{
            position: absolute;
            content: "";
            height: 1px;
            left: 0px;
            right: 0px; 
            transform: translateX(30px);          
        }
    }

    

    @media only screen and (min-width: 1024px){
        font-size: 1.375rem;
    }
`;

const TabSpacer = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
`;

const TabContainer = styled.ul<TabProps>`
    display: ${props => props.activeTab === props.tabNr ? "flex" : "none"};
    display: ${props => props.activeTab === props.tabNr ? "grid" : "none"};
    flex-wrap: wrap;    
    grid-template-columns: repeat(3, minmax(0,1fr));  
    grid-gap: 2.2vw;//7.5px;
    list-style: none;
    max-width: 100%;
    // ul overrides:
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px; 

    @media only screen and (min-width: 1024px){
        //grid-template-columns: repeat(auto-fit, 150px); 
        grid-gap: 2.2vw//13px;
    }  

    @media only screen and (min-width: 1600px){
        //grid-template-columns: repeat(auto-fit, 150px); 
        grid-gap: 35px;//13px;
    }  
`;

const ListItem = styled.li`  
    width: 100%;
`;

const ElementContainer = styled.button`
    align-items: center;
    width: 100%;
    position: relative;
    background: none;
    border: none;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;    
    scroll-snap-align: start;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;  
    

    //box-shadow: 0 0 0 1000px rgba(0,0,0, 0.05) inset;
    &::after{
        position: absolute;
        content: "";
        background-color: black;
        opacity: 0.05;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: 100;
    }    

    &:hover{
        transform: scale(1.1);
        z-index: 99;        
    }
`;
interface ImageContainerProps {
    hasText: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25vw; // 100px;
    width: 25vw; //100px; 
    //width: 100%;   
    object-fit: contain;    
    overflow: hidden;
    padding-top: ${props=>props.hasText ? "1rem" : "0"};
    
    @media only screen and (min-width: 1024px){
        //height: 134px;
        //width: 150px;
        height: 13vw; // 100px;
        width: 13vw; //100px;
    }
    @media only screen and (min-width: 1600px){
        //height: 134px;
        //width: 150px;
        height: 210px; // 100px;
        width: 210px; //100px;
    }
`;

const ImageLabel = styled.div`
    display: flex;    
    padding: 10px 2px;
    hyphens: auto;
    @media only screen and (min-width: 1024px){
        text-transform: uppercase;
        overflow-wrap: anywhere; // hyphens does not work on uppercase
    }
`;

const NamePlaceholder = styled.div`
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;
    font-size: calc(3vw);
    font-weight: 600;

    @media only screen and (min-width: 1024px){
        font-size: 25px;
        font-weight: 800;
    }

    & p{
        margin: 0px;
    }

    & i {
        font-style: normal;
        color: #A96E2D
    }
`;


interface TabProps {
    tabNr: number;
    activeTab: number;
}

interface TabBtnProps {
    isActive?: boolean;
    tabNr: number;
}

export interface GalleryData {
    title: object;
    routerUrl: string;
    imageUrl: string;
    uuid: string;
    menuName: object;
}

interface GalleryProps {
    tabTitles: any[];
    data: [GalleryData[]];
}

const Gallery: React.FC<GalleryProps> = ({ data, tabTitles }) => {
    //const Router = useRouter();
    const { state, dispatch } = useContext(Store);
    const [errorState, setErrorState] = useState(false);
    const setErrorStateRef = useRef(setErrorState);
    const stateRef = useRef(state);
    const { t, i18n } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(0);
    const setActiveTabRef = useRef(setActiveTab);
    const tabRef = useRef(new Array(data.length));
    const handleTabClick = (tabNr: number) => {
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', "tab_click", {
                'event_category': "user_interaction"
            });
        tabRef.current[tabNr].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        setActiveTab(tabNr);
    }

    const handleTabNavPress = (evt) => {
        //evt.preventDefault();
        //console.log(evt.keyCode);
        //console.log(tabRef.current)
        if (evt.keyCode === 39) {
            if (activeTab + 1 < data.length) {
                tabRef.current[activeTab + 1].focus()
                tabRef.current[activeTab + 1].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                setActiveTab(prev => prev + 1);
            }
        }
        else if (evt.keyCode === 37) {
            if (activeTab - 1 >= 0) {
                setActiveTab(prev => prev - 1);
                tabRef.current[activeTab - 1].focus()
                tabRef.current[activeTab - 1].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
        // TODO : ACTIVETAB ++ osv
    }



    //console.log("GalleryData: ", data);
    //console.log("tabTitles: ", tabTitles);


    useEffect(() => {
        //console.log("stateRef.current.data.tabSettings: ",stateRef.current.data.tabSettings)
        if (!data[0].every(el => el !== undefined) || Object.keys(stateRef.current.data.tabSettings).length < 1) {
            setErrorStateRef.current(true)
            return
        }


        if (stateRef.current.data.tabSettings[data[0][0].uuid]?.tabIndex) {
            //console.log("FOUND INDEX");
            const savedTab = stateRef.current.data.tabSettings[data[0][0].uuid].tabIndex;
            setActiveTabRef.current(savedTab);
            tabRef.current[savedTab].focus()
            tabRef.current[savedTab].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }

    }, [data])

    return (
        <>

            {data[0].every(el => el !== undefined) ?
                <Container>

                    <TabNav
                        role="tablist"
                    >
                        {/*console.log("GalleryData: ", data)*/}

                        {data.length >= 2 && data.map((el, idx) =>
                            <TabBtn
                                key={`tabbtn_${idx}`}
                                isActive={activeTab === idx}
                                tabNr={idx}
                                onClick={(evt) => handleTabClick(idx)}
                                onKeyDown={handleTabNavPress}
                                tabIndex={activeTab === idx ? 0 : -1}
                                role="tab"
                                id={"tab_" + idx}
                                aria-selected={activeTab === idx}
                                aria-controls={"panel_" + idx}
                                ref={refEl => tabRef.current[idx] = refEl}
                            >
                                {tabTitles[idx] ? tabTitles[idx][`locale:${shortLangCode(i18n.language)}`] : `${t("general.tab")} ${idx + 1}`/*t("general.tab")} {idx + 1*/}
                            </TabBtn>

                        )}
                        {/*<TabSpacer/>*/}
                    </TabNav>
                    {data.map((el, idx) =>
                        <TabContainer
                            key={"tabcontainer_" + idx}
                            tabNr={idx}
                            activeTab={activeTab}
                            role="tabpanel"
                            id={"panel_" + idx}
                            aria-labelledby={data.length >= 2 && "tab_" + idx}
                        >
                            {el.map((artEl, artIdx) => {
                                const artElMenuName = artEl?.menuName ? artEl?.menuName[`locale:${shortLangCode(i18n.language)}`] : "";
                                const artElTitle = artEl?.title ? artEl?.title[`locale:${shortLangCode(i18n.language)}`] : "";
                                return (<ListItem key={`${artEl.uuid}_${idx}_${artIdx}`}>
                                    
                                    <ElementContainer
                                        onClick={() => {
                                            dispatch({
                                                type: "SET_DATA_TABSETTINGS",
                                                payload: { [data[0][0].uuid]: { tabIndex: activeTab } }
                                            })
                                            navigate(`/artifact/${artEl.routerUrl}`);
                                        }}

                                        tabIndex={0}
                                        aria-label={artEl.title[`locale:${shortLangCode(i18n.language)}`]}>
                                        <ImageContainer hasText={!!artElMenuName} /*alt={artEl.title[`locale:${shortLangCode(i18n.language)}`]} src={artEl.imageUrl || placeholderImage}*/>
                                            {artEl.imageUrl ?
                                                <>
                                                    <ImageLoader
                                                        imgUrl={artEl.imageUrl || placeholderImage}
                                                        maxSize={600}
                                                        alt={artEl.title[`locale:${shortLangCode(i18n.language)}`]}
                                                    ></ImageLoader>
                                                    {artElMenuName && <ImageLabel>{artElMenuName}</ImageLabel>}
                                                </>
                                                :
                                                <NamePlaceholder>{(artElMenuName || artElTitle).split(" ").map(
                                                    (titleWord, twIdx, twArr) => {

                                                        return (
                                                            <>
                                                                {twIdx < twArr.length - 1 ? <p>{titleWord}</p> : <i>{titleWord}</i>}
                                                            </>
                                                        )
                                                    }
                                                )}</NamePlaceholder>
                                            }
                                        </ImageContainer>
                                    </ElementContainer>
                                </ListItem>)
                            })}

                        </TabContainer>
                    )}
                </Container>
                :
                <PageLoader showError={errorState} />
            }
        </>
    );
}

export default Gallery;