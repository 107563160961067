import React, { useContext } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { KiService } from "../../common/api/Kulturio/KiService";
import { shortLangCode } from "../../common/helpers/helpers";
import { zoneType } from "../../common/types/types";
import Gallery from "../../components/framework/Gallery/gallery";
import PageLoader from "../../components/framework/PageLoader/pageLoader";
import ParagraphText from "../../components/framework/ParagraphText/paragraphText";
import HeadingContainer from "../../components/modules/HeadingContainer/headingContainer";
import PageWrapper from "../../components/PageWrapper/pageWrapper";
import { Store } from "../../components/Store/store";
import { GalleryData } from "../../components/framework/Gallery/gallery";
import SlideShow from "../../components/framework/SlideShow/slideShow";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: var(--marginLeftSm);
    margin-right: var(--marginLeftSm);

    @media only screen and (min-width: 1024px){
        margin-left: var(--marginLeftLg);
        margin-right: var(--marginLeftLg);
    }
`;

const Header2 = styled.h2`
    color: var(--colorPrimary);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5625rem;
    line-height: 1.9375rem;
    margin: 0;    
`;

const SubHeader = styled.p`
    color: var(--colorSecondary);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin: 0;
`;

const Paragraph = styled.p`
    color: var(--colorPrimary);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
`;

const EndMargin = styled.div`
    margin-bottom: 60px;
`;


const ThemePage = ({ params }) => {
    const { state } = useContext(Store)
    //const Router = useRouter();
    const tabTitles = useRef([]);
    const themeId = { theme_id: params.theme_id };//Router.query;
    const pageUrl = new URL(`${state.constants.siteUrl}/theme/${themeId.theme_id}`);
    const zone: React.MutableRefObject<zoneType> = useRef(null);
    const [stopReaderAudio, setStopReaderAudio] = useState(false);
    const [zoneData, setZoneData] = useState([]);
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation("common");
    const [artifacts, setArtifacts] = useState([]);
    const backObj = useRef({ title: "general.room", url: "/" });
    const [errorState, setErrorState] = useState(false);
    const setErrorStateRef = useRef(setErrorState);
    const image = data.length && data[0] !==undefined && data[0].content?.image?.media?.resources?.png?.url ? data[0].content.image.media.resources.png.url : "";
    //console.log("\n\n\n\nimage: ", image)

    const getAudioUrl = () => {
        let _audioUrl;
        try {
            _audioUrl = data.length > 0 && (shortLangCode(i18n.language) === "no" ? data[0].content.audio.norwegian.media.resources.mp3.url : data[0].content.audio.english.media.resources.mp3.url);
        }
        catch (err) {
            //console.log(err);
            _audioUrl = undefined;
        }
        finally {
            return _audioUrl;
        }
    }

    const audioUrl = getAudioUrl();

    useEffect(() => {

        if (!state.data.zones.length) {
            //console.log("STATE");
            return;
        }

        if (data.length && zoneData.length) return;
        if (artifacts.length) return;


        const apiKiService = new KiService();

        const parseArtifacts = (_data) => {
            const artifactsTmp = [];

            let _artifactsFetch: any;
            try {
                _artifactsFetch = _data.content.tabs.map((tabElem) => {
                    tabTitles.current.push(tabElem.title);
                    return new Promise(resolve => {
                        if (tabElem.artifacts === undefined) {
                            artifactsTmp.push([]);
                            resolve([]);
                            //return [];
                        }
                        Promise.all(tabElem.artifacts.map(async (_artElem) => {
                            return apiKiService.getByUuid(_artElem.reference.uniqueId);
                        }))
                            .then((res: any[]) => {
                                /*console.log("res themeid promise before map", res);*/
                                const _artifacts = res.map((artElem, idx) => {
                                    if (artElem === undefined) return undefined;                                    
                                    const imageUrl: string = artElem.imageUrl;
                                    const routerUrl: string = themeId.theme_id.toString() + "_" + artElem.uniqueId;
                                    const title: Object = artElem.content.name;
                                    const uuid: string = artElem.uniqueId;
                                    const menuName:Object = artElem.content.menuName;
                                    return { imageUrl, routerUrl, title, uuid, menuName};
                                });
                                artifactsTmp.push(_artifacts);
                                resolve(_artifacts);
                            }).catch((err)=>{
                                console.log("Error: ", err);
                                setErrorStateRef.current(true);
                            });
                    });
                });
            }
            catch (err) {
                console.log(err);
                _artifactsFetch = [[new Promise((res) => { res({}) })]];
            }
            Promise.all(_artifactsFetch).then(res => {
                setArtifacts(res);
            }).catch((err)=>{
                console.log("Error: ", err);
                setErrorStateRef.current(true);});
        }

        const themeIdSplit: Array<string> = themeId.theme_id.toString().split("_");
        const zoneTmp: zoneType = themeIdSplit[0] as zoneType;
        const themeIdTmp: number = parseInt(themeIdSplit[1]);
        zone.current = zoneTmp;
        backObj.current.url = `/room/${zoneTmp}`

        if (!data.length && zoneData.length && !zoneData.every(el => el === undefined)) {
            /*console.log("zonedata", zoneData[0].content.displays);*/
            const themeUUId = zoneData[0].content.displays.find((el) => el.reference.id === themeIdTmp).reference.uniqueId;

            apiKiService.getByUuid(themeUUId)
                .then((_themeData) => {
                    if(_themeData === undefined){
                        setData([])
                        setErrorStateRef.current(true);
                    }
                    else {
                        setData([_themeData]);
                    }
                    parseArtifacts(_themeData);
                });
        } else if (zoneData.every(el => el === undefined) && zoneData.length) {
            setErrorStateRef.current(true);
        }


        if (!zoneData.length) {
            setZoneData([state.data.zones[parseInt(zone.current.toString()) - 1]])
        }
    }, [themeId, state.data.zones]);


    return (
        <PageWrapper
            backObj={backObj.current}
            pageTitle={data.length && data[0] !== undefined ? data[0].content.title[`locale:${shortLangCode(i18n.language)}`] + " - Tidsrom" : "Tidsrom"}
            pageUrl={pageUrl}
            description={`${data.length &&  data[0] !== undefined && data[0].content?.body && Object.keys(data[0].content?.body).length > 0 ? data[0].content.body[`locale:${shortLangCode(i18n.language)}`] : ""}`}
            image={image}
        >
            {/*console.log(`data.len: ${data.length}, zoneData.len: ${zoneData.length}, artifacts.len: ${artifacts.length}`)*/}
            {

                data.length && zoneData.length && artifacts.length ?
                    <Container>
                        {image &&
                            <SlideShow
                                imagesList={image ? [image] : []}
                                clickToFullscreen={true} />
                        }
                        <HeadingContainer soundUrl={audioUrl} stopReaderAudio={stopReaderAudio} setStopReaderAudio={setStopReaderAudio}>
                            {data.length && data[0] !== undefined ? data[0].content.title[`locale:${shortLangCode(i18n.language)}`] : ""}
                        </HeadingContainer>
                        <SubHeader>
                            {`${t("general.room")} ${zone.current} - ${zoneData.length ? zoneData[0].content.title[`locale:${shortLangCode(i18n.language)}`] : ""}`}
                        </SubHeader>
                        
                        <ParagraphText numChars={50} text={`${data.length &&  data[0] !== undefined && data[0].content?.body && Object.keys(data[0].content?.body).length > 0 ? data[0].content.body[`locale:${shortLangCode(i18n.language)}`] : ""}`}
                        />
                        {data.reduce((acc, el) => { return (acc + (el.collectionId && el.content && el.content.tabs && el.content.tabs[0].artifacts.length > 0)) }, 0) > 0 &&
                            <>
                                <Header2>{t("general.exploreArtifacts")}</Header2>
                                
                                <Gallery
                                    tabTitles={tabTitles.current}
                                    data={artifacts as [GalleryData[]]} />
                            </>
                        }
                        <EndMargin />
                    </Container>
                    :
                    <PageLoader showError={errorState} />
            }
        </PageWrapper>
    );
}

export default ThemePage;
